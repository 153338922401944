<template>
  <div>
    <b-card>
      <b-tabs pills>
        <b-tab>
          <template #title>
            <feather-icon icon="TagIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Routes.editProvider') }}</span>
          </template>
          <provider-edit-tab/>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Routes.Contacts') }}</span>
          </template>
          <provider-contact />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import tiService from '@/services/informationTecnology.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import ProviderEditTab from './ProviderEdit.vue'
import ProviderContact from './ProviderContact.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    ProviderEditTab,
    ProviderContact,
  },
  setup() {
    const providerData = ref(null)
    const { fetchProvider } = tiService()
    fetchProvider(router.currentRoute.params.proveedorTiId, data => {
      providerData.value = data
    })

    return {
      providerData,
    }
  },

}
</script>
