<template>
  <div>
    <list
      ref="refProviderContacList"
      sort-by="proveedorTiContactoId"
      refetch-records-name="contactos"
      key-field="proveedorTiContactoId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fecthRecord"
      :delete-method="deleteContact"
      :sort-dir-desc="false"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import tiService from '@/services/informationTecnology.service'
import List from '@/components/List.vue'
import router from '@/router'

export default {
  components: {
    List,
  },
  setup() {
    const refProviderContacList = ref(null)
    const { fetchContacts, deleteContact } = tiService()
    const filters = ref([])

    const fecthRecord = (data, callback) => {
      fetchContacts(router.currentRoute.params.proveedorTiId, data, records => {
        callback(records)
      })
    }

    const tableColumns = [
      {
        key: 'proveedorTiContactoId',
        label: i18n.t('AppTI.columns.id'), // idioma
        sortable: true,
        sortKey: 'ProveedorTiContactoId', // idioma
      },
      {
        key: 'nombre',
        label: i18n.t('AppTI.columns.name'), // idioma
        sortable: false,
      },
      {
        key: 'puesto',
        label: i18n.t('AppTI.columns.email'), // idioma
        sortable: false,
      },
      {
        key: 'telefono',
        label: i18n.t('AppTI.contact.phone'), // idioma
        sortable: false,
      },
      {
        key: 'correo',
        label: i18n.t('AppTI.contact.email'), // idioma
        sortable: false,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'), // idioma
        aclAction: 'read',
        aclResource: 'MesaAyuda',
        routeName: 'apps-provider-contact-edit',
        params: ['proveedorTiContactoId'],
        icon: 'Edit2Icon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'ProveedorTiContactoId',
        params: ['proveedorTiContactoId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        label: i18n.t('Routes.newContact'), // idioma
        aclAction: 'create',
        aclResource: 'MesaAyuda',
        routeName: 'apps-provider-contact-new',
        icon: 'PlusCircleIcon',
      },
    ])

    return {
      refProviderContacList,
      fecthRecord,
      deleteContact,
      filters,
      tableColumns,
      tableActions,
      actions,
    }
  },
}
</script>
